<template>
  <div class="evaluation-analysis">
    <div class="tab-wrap">
      <span
        :class="[componentsName === 'storeeValuation' ? 'active' : '']"
        @click="tabClick('storeeValuation')"
      >店铺综合评测</span>
      <span
        :class="[componentsName === 'comprehensiveValuation' ? 'active' : '']"
        @click="tabClick('comprehensiveValuation')"
      >综合评测对比</span>
    </div>
    <div
      :is="componentsName"
      :filtroProject="filtroProject"
      :filtroStore="filtroStore"
      :projectName="projectName"
    ></div>
  </div>
</template>

<script>
import storeeValuation from '@/views/storeEarlywarning/evaluationAnalysis/components/storeValuation.vue'
import comprehensiveValuation from '@/views/storeEarlywarning/evaluationAnalysis/components/comprehensiveValuation.vue'
export default {
  name: 'evaluationAnalysis',
  components: {
    storeeValuation,
    comprehensiveValuation
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    projectName: {
      type: String
    }
  },
  data () {
    return {
      componentsName: 'storeeValuation'
    }
  },
  mounted () {
    // console.log(this.filtroProject)
    this.$nextTick(() => {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
    })
  },
  watch: {
    filtroProject (newValue, oldValue) {
      console.log('filtroProject', this.filtroProject)
    },
    filtroStore (newValue, oldValue) {
      console.log('filtroStore', this.filtroStore)
    }
  },
  methods: {
    tabClick (name) {
      this.componentsName = name
    }
  }
}
</script>

<style lang="stylus" scoped>
.tab-wrap
  padding-left 24px
  background-color #272930
  margin-right 23px
  padding-bottom 17px
  padding-top 17px
  border-bottom 1px solid #373A43
  span
    font-size 18px
    // font-weight bold
    color #808191
    padding 17px 0
    margin-right 70px
    cursor pointer
    &.active
      color #fff
      border-bottom 2px solid #FFA134
      font-weight bold
</style>
