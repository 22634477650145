<template>
  <div class="evaluation-wrap">
    <div
      class="evaluation-store"
      v-if="haveData"
      v-loading.fullscreen.lock="loading"
      element-loading-text="加载中..."
    >
      <div class="store-analysis">
        <div class="title">店铺综合评测分析</div>
        <div class="analysis-info">
          <div class="info-left">
            <div class="text">
              店铺经营指数：<span>{{storeGxdBaseInfoVo.evaluationScore}}分</span>高于<i>{{storeGxdBaseInfoVo.highEvaluationScore}}%</i> 的同楼层同业态商户
            </div>
            <div class="charts">
              <div id="radar"></div>
            </div>
          </div>
          <div class="info-right">
            <div class="item-content">
              <div class="item">
                <div class="name">租金贡献度</div>
                <div class="percentage">{{storeGxdBaseInfoVo.yearRentEffectRatio}}%</div>
                <div class="text"> 高于 <span>{{storeGxdBaseInfoVo.highRentEffectRatio}}%</span> 同楼层同业态店铺</div>
              </div>
              <div class="item">
                <div class="name">费用收缴率</div>
                <div class="percentage">{{storeGxdBaseInfoVo.yearCaptureRate}}%</div>
                <div class="text"> 高于 <span>{{storeGxdBaseInfoVo.highCaptureRate}}%</span> 同楼层同业态店铺</div>
              </div>
              <div class="item">
                <div class="name">租决完成率</div>
                <div class="percentage">{{storeGxdBaseInfoVo.yearTargetRentCompleteRatio}}%</div>
                <div class="text"> 高于 <span>{{storeGxdBaseInfoVo.highTargetRentComplete}}%</span> 同楼层同业态店铺</div>
              </div>
              <div class="item">
                <div class="name">客流贡献度</div>
                <div class="percentage">{{storeGxdBaseInfoVo.yearPassengerFlowRatio}}%</div>
                <div class="text"> 高于 <span>{{storeGxdBaseInfoVo.highPassengerFlowRatio}}%</span> 同楼层同业态店铺</div>
              </div>
              <div class="item">
                <div class="name">销售贡献度</div>
                <div class="percentage">{{storeGxdBaseInfoVo.yearSalesEffectRatio}}%</div>
                <div class="text"> 高于 <span>{{storeGxdBaseInfoVo.highSalesEffectRatio}}%</span> 同楼层同业态店铺</div>
              </div>
            </div>
            <div class="tips-content">
              <div class="tips-item">
                <span>店铺经营指数：</span>
                <p>综合店铺在项目中的租金贡献度、费用收缴率、销售贡献度、租决完成率、客流贡献度五个核心指标，形成五力模型，对当前店铺在项目运营中进行全面评测店铺综合评测指数越高，说明该店铺在购物中心的运营情况更优。</p>
              </div>
              <div class="tips-item">
                <span>租金贡献度含义：</span>
                <p>近一年本店铺的租金坪效相对同楼层同业态店铺平均租金坪效的比值。比值越大，相对同楼层同业态的店铺，本店具备更强的承租能力。</p>
              </div>
              <div class="tips-item">
                <span>费用收缴率含义：</span>
                <p>近一年本店铺各项费用的缴交情况。收缴率越高，代表该店铺具备更强的履约能力，具备更高的经营信用。</p>
              </div>
              <div class="tips-item">
                <span>租决完成率含义：</span>
                <p>近一年本店铺的租金相对同期租决的完成比例。租决完成率越高，代表该店铺合同租金更接近租金决策标准。</p>
              </div>
              <div class="tips-item">
                <span>客流贡献度含义：</span>
                <p>近一年进入本店铺的客流相对同楼层同业态店铺平均客流的比值。比值越大，相对同楼层同业态的店铺，本店具备更强的引流能力。</p>
              </div>
              <div class="tips-item">
                <span>销售贡献度含义：</span>
                <p>近一年本店铺的销售坪效相对同楼层同业态店铺平均销售坪效的比值。比值越大，相对同楼层同业态的店铺，本店具备更强的销售能力。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 租金贡献度对比 -->
      <div class="charts-wrap">
        <h3 class="w-title-gradients">租金贡献度对比<span>(本店铺黄色高亮显示)</span> </h3>
        <div class="charts-content">
          <div class="content-left">
            <div class="title">同楼层同业态店铺租金贡献度对比</div>
            <div class="charts-info">
              <div class="info">
                <span class="span1"></span> 租金贡献度
                <span
                  class="span2"
                  style="background: #A0D7E7;"
                ></span> 同楼层同业态平均租金贡献度
              </div>
              <div id="rent"></div>
            </div>
            <div class="project-text color1">统计最接近当前店铺租金贡献度的{{length1}}个店铺</div>
          </div>
          <div class="content-right">
            <div class="title">
              <div class="title-left">
                <div class="project-name">{{projectName}}同业态租金贡献度排行</div>
              </div>
              <div class="title-right">
                <div class="w-select-screen-border">
                  <el-select
                    popper-class="w-block-select-down w-block-select-down-border"
                    v-model="selectType1"
                    @change="changeTop1"
                    placeholder="请选择"
                    size='medium'
                    class="select-extra"
                  >
                    <el-option
                      v-for="item in selectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <td width="10%">排序</td>
                    <td width="25%">店铺名称</td>
                    <td width="20%">楼层</td>
                    <td width="25%">租金坪效(元/月/㎡)</td>
                    <td width="20%">租金贡献度</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in tableList1"
                    :key="index"
                    :class="[item.isCurrentStore === 1 ? 'active' : '']"
                  >
                    <td>{{index + 1 }}</td>
                    <td>{{item.storeName}}</td>
                    <td>{{item.floorName}}</td>
                    <td>{{item.avgRentEffect}}</td>
                    <td>{{item.yearRentEffectRatio}}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="project-text color1">在本项目同业态店铺中，本店铺排名{{storeIndex1}}名</div>
          </div>
        </div>
      </div>
      <!-- 费用收缴率对比 -->
      <div class="charts-wrap">
        <h3 class="w-title-gradients">费用收缴率对比<span>(本店铺黄色高亮显示)</span></h3>
        <div class="charts-content">
          <div class="content-left">
            <div class="title">同楼层同业态店铺费用收缴率对比</div>
            <div class="charts-info">
              <div class="info">
                <span
                  class="span1"
                  style="background: #FFCE73;"
                ></span> 费用收缴率
                <span
                  class="span2"
                  style="background: #7FBA7A;"
                ></span> 同楼层同业态平均费用收缴率
              </div>
              <div id="cost"></div>
            </div>
            <div class="project-text color2">统计最接近当前店铺费用收缴率的{{length2}}个店铺</div>
          </div>
          <div class="content-right">
            <div class="title">
              <div class="title-left">
                <div class="project-name">{{projectName}}同业态费用收缴率排行</div>
              </div>
              <div class="title-right">
                <div class="w-select-screen-border">
                  <el-select
                    popper-class="w-block-select-down w-block-select-down-border"
                    v-model="selectType2"
                    @change="changeTop2"
                    placeholder="请选择"
                    size='medium'
                    class="select-extra"
                  >
                    <el-option
                      v-for="item in selectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <td width="25%">排序</td>
                    <td width="25%">店铺名称</td>
                    <td width="25%">楼层</td>
                    <td width="25%">费用收缴率</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in tableList2"
                    :key="index"
                    :class="[item.isCurrentStore === 1 ? 'active' : '']"
                  >
                    <td>{{index + 1 }}</td>
                    <td>{{item.storeName}}</td>
                    <td>{{item.floorName}}</td>
                    <td>{{item.yearCaptureRate}}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="project-text color2">在本项目同业态店铺中，本店铺排名{{storeIndex2}}名</div>
          </div>
        </div>
      </div>
      <!-- 租决完成率对比 -->
      <div class="charts-wrap">
        <h3 class="w-title-gradients">租决完成率对比<span>(本店铺黄色高亮显示)</span></h3>
        <div class="charts-content">
          <div class="content-left">
            <div class="title">同楼层同业态店铺租决完成率对比</div>
            <div class="charts-info">
              <div class="info">
                <span
                  class="span1"
                  style="background: #A0D7E7;"
                ></span> 租决完成率
                <span
                  class="span2"
                  style="background: #FFA2C0;"
                ></span> 同楼层同业态平均租决完成率
              </div>
              <div id="lease"></div>
            </div>
            <div class="project-text color3">统计最接近当前店铺租决完成率的{{length3}}个店铺</div>
          </div>
          <div class="content-right">
            <div class="title">
              <div class="title-left">
                <div class="project-name">{{projectName}}同业态租决完成率排行</div>
              </div>
              <div class="title-right">
                <div class="w-select-screen-border">
                  <el-select
                    popper-class="w-block-select-down w-block-select-down-border"
                    v-model="selectType3"
                    @change="changeTop3"
                    placeholder="请选择"
                    size='medium'
                    class="select-extra"
                  >
                    <el-option
                      v-for="item in selectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <td width="25%">排序</td>
                    <td width="25%">店铺名称</td>
                    <td width="25%">楼层</td>
                    <td width="25%">租决完成率</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in tableList3"
                    :key="index"
                    :class="[item.isCurrentStore === 1 ? 'active' : '']"
                  >
                    <td>{{index + 1 }}</td>
                    <td>{{item.storeName}}</td>
                    <td>{{item.floorName}}</td>
                    <td>{{item.value}}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="project-text color3">在本项目同业态店铺中，本店铺排名{{storeIndex3}}名</div>
          </div>
        </div>
      </div>
      <!-- 客流贡献度对比 -->
      <div class="charts-wrap">
        <h3 class="w-title-gradients">客流贡献度对比<span>(本店铺黄色高亮显示)</span></h3>
        <div class="charts-content">
          <div class="content-left">
            <div class="title">同楼层同业态店铺客流贡献度对比</div>
            <div class="charts-info">
              <div class="info">
                <span
                  class="span1"
                  style="background: #FFA2C0;"
                ></span> 客流贡献度
                <span
                  class="span2"
                  style="background: #A0D7E7;"
                ></span> 同楼层同业态平均客流贡献度
              </div>
              <div id="flow"></div>
            </div>
            <div class="project-text color4">统计最接近当前店铺客流贡献度的{{length4}}个店铺</div>
          </div>
          <div class="content-right">
            <div class="title">
              <div class="title-left">
                <div class="project-name">{{projectName}}同业态客流贡献度排行</div>
              </div>
              <div class="title-right">
                <div class="w-select-screen-border">
                  <el-select
                    popper-class="w-block-select-down w-block-select-down-border"
                    v-model="selectType4"
                    @change="changeTop4"
                    placeholder="请选择"
                    size='medium'
                    class="select-extra"
                  >
                    <el-option
                      v-for="item in selectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <td width="25%">排序</td>
                    <td width="25%">店铺名称</td>
                    <td width="25%">楼层</td>
                    <td width="25%">客流贡献度</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in tableList4"
                    :key="index"
                    :class="[item.isCurrentStore === 1 ? 'active' : '']"
                  >
                    <td>{{index + 1 }}</td>
                    <td>{{item.storeName}}</td>
                    <td>{{item.floorName}}</td>
                    <td>{{item.yearPassengerFlowRatio}}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="project-text color4">在本项目同业态店铺中，本店铺排名{{storeIndex4}}名</div>
          </div>
        </div>
      </div>
      <!-- 销售贡献度对比 -->
      <div class="charts-wrap">
        <h3 class="w-title-gradients">销售贡献度对比<span>(本店铺黄色高亮显示)</span></h3>
        <div class="charts-content">
          <div class="content-left">
            <div class="title">同楼层同业态店铺销售贡献度对比</div>
            <div class="charts-info">
              <div class="info">
                <span
                  class="span1"
                  style="background: #8270FF;"
                ></span> 销售贡献度
                <span
                  class="span2"
                  style="background: #A0D7E7;"
                ></span> 同楼层同业态平均销售贡献度
              </div>
              <div id="sale"></div>
            </div>
            <div class="project-text color5">统计最接近当前店铺销售贡献度的{{length5}}个店铺</div>
          </div>
          <div class="content-right">
            <div class="title">
              <div class="title-left">
                <div class="project-name">{{projectName}}同业态销售贡献度排行</div>
              </div>
              <div class="title-right">
                <div class="w-select-screen-border">
                  <el-select
                    popper-class="w-block-select-down w-block-select-down-border"
                    v-model="selectType5"
                    @change="changeTop5"
                    placeholder="请选择"
                    size='medium'
                    class="select-extra"
                  >
                    <el-option
                      v-for="item in selectList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <td width="20%">排序</td>
                    <td width="20%">店铺名称</td>
                    <td width="20%">楼层</td>
                    <td width="20%">销售坪效<span>(元/月/㎡)</span></td>
                    <td width="20%">销售贡献度</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in tableList5"
                    :key="index"
                    :class="[item.isCurrentStore === 1 ? 'active' : '']"
                  >
                    <td>{{index + 1 }}</td>
                    <td>{{item.storeName}}</td>
                    <td>{{item.floorName}}</td>
                    <td>{{item.avgSalesEffect}}</td>
                    <td>{{item.yearSalesEffectRatio}}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="project-text color5">在本项目同业态店铺中，本店铺排名{{storeIndex5}}名</div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="no-data"
      v-if="hasData"
    >
      <img
        src="@/assets/images/public/not-plandata.png"
        alt=""
      >
      <p>抱歉，暂无数据</p>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
export default {
  data () {
    return {
      loading: true,
      dataInfo: {},
      targetInfo: {},
      storeGxdBaseInfoVo: {},
      radarData: [],
      selectType1: 'top10',
      selectType2: 'top10',
      selectType3: 'top10',
      selectType4: 'top10',
      selectType5: 'top10',
      selectList: [{
        label: 'top 10',
        value: 'top10'
      }, {
        label: 'last 10',
        value: 'last10'
      }],
      tableList1: [],
      storeIndex1: 0,
      tableList2: [],
      storeIndex2: 0,
      tableList3: [],
      storeIndex3: 0,
      tableList4: [],
      storeIndex4: 0,
      tableList5: [],
      storeIndex5: 0,
      radarText: ['租金贡献度', '费用收缴率', '租决完成率', '客流贡献度', '销售贡献度'],
      hasData: false,
      haveData: true,
      length1: 0,
      length2: 0,
      length3: 0,
      length4: 0,
      length5: 0
    }
  },

  mounted () {
    this.init()
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    projectName: {
      type: String
    }
  },
  methods: {
    init () {
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.get(api.getEvaluationAnalysisByStoreCode, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.haveData = true
          this.hasData = false
          this.dataInfo = res.data.data
          this.storeGxdBaseInfoVo = res.data.data.storeGxdBaseInfoVo
          this.storeLcytGxdAvgVo = res.data.data.storeLcytGxdAvgVo
          this.radarData.push(this.storeGxdBaseInfoVo.yearRentEffectRatio)
          this.radarData.push(this.storeGxdBaseInfoVo.yearCaptureRate)
          this.radarData.push(this.storeGxdBaseInfoVo.yearTargetRentCompleteRatio)
          this.radarData.push(this.storeGxdBaseInfoVo.yearPassengerFlowRatio)
          this.radarData.push(this.storeGxdBaseInfoVo.yearSalesEffectRatio)
          this.radarCharts()
          // 租金贡献度对比
          this.rentCharts(res.data.data.rentEffectGxdRatioList)
          this.tableList1 = res.data.data.rentEffectGxdRank.topList
          this.storeIndex1 = res.data.data.rentEffectGxdRank.storeRank
          // 费用收缴率对比
          this.costCharts(res.data.data.captureRateList)
          this.tableList2 = res.data.data.captureRateRank.topList
          this.storeIndex2 = res.data.data.captureRateRank.storeRank
          // 客流贡献度对比
          this.flowCharts(res.data.data.passengerFlowGxdRatioList)
          this.tableList4 = res.data.data.passengerFlowGxdRank.topList
          this.storeIndex4 = res.data.data.passengerFlowGxdRank.storeRank
          // 销售贡献度对比
          this.saleCharts(res.data.data.salesEffectGxdRatioList)
          this.tableList5 = res.data.data.salesEffectGxdRank.topList
          this.storeIndex5 = res.data.data.salesEffectGxdRank.storeRank
        } else {
          this.haveData = false
          this.hasData = true
        }
      })
      this.axios.get(api.getTargetRentCompleteRatioCompare, { params: params }).then(res => {
        if (res.data.data.chart) {
          this.targetInfo = res.data.data
          this.leaseCharts(res.data.data.chart)
          this.tableList3 = res.data.data.rank.topTen
          this.storeIndex3 = res.data.data.rank.storeIndex
        }
      })
    },
    changeTop1 (val) {
      if (val === 'top10') {
        this.tableList1 = this.dataInfo.rentEffectGxdRank.topList
      } else {
        this.tableList1 = this.dataInfo.rentEffectGxdRank.lastList
      }
    },
    changeTop2 (val) {
      if (val === 'top10') {
        this.tableList2 = this.dataInfo.captureRateRank.topList
      } else {
        this.tableList2 = this.dataInfo.captureRateRank.lastList
      }
    },
    changeTop3 (val) {
      if (val === 'top10') {
        this.tableList3 = this.targetInfo.rank.topTen
      } else {
        this.tableList3 = this.targetInfo.rank.lastTen
      }
    },
    changeTop4 (val) {
      if (val === 'top10') {
        this.tableList4 = this.dataInfo.passengerFlowGxdRank.topList
      } else {
        this.tableList4 = this.dataInfo.passengerFlowGxdRank.lastList
      }
    },
    changeTop5 (val) {
      if (val === 'top10') {
        this.tableList5 = this.dataInfo.salesEffectGxdRank.topList
      } else {
        this.tableList5 = this.dataInfo.salesEffectGxdRank.lastList
      }
    },
    radarCharts () {
      const max = Math.max(...this.radarData)
      const maxNum = (max > 100 ? max : 100)
      const myChart = echarts.init(document.getElementById('radar'))
      const option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        legend: {
          left: 'center',
          data: [],
          show: false
        },
        formatter: (params) => {
          let relVal = '<span style="color: #B2B3BD;">' + params.name + '</span>'
          for (let i = 0; i < params.data.value.length; i++) {
            relVal += '<br/><span style="color: #808191;">' + this.radarText[i] + ' : </span>' + params.data.value[i] + ' ' + '%'
          }
          return relVal
        },
        radar: [
          {
            indicator: [
              {
                text: '租金贡献度',
                max: maxNum,
                // 若将此属性放在radar下，则每条indicator都会显示圈上的数值，放在这儿，只在通信这条indicator上显示
                axisTick: {
                  show: true,
                  fontSize: 12,
                  color: '#838D9E',
                  showMaxLabel: false, // 不显示最大值，即外圈不显示数字30
                  showMinLabel: true // 显示最小数字，即中心点显示0
                }
              },
              { text: '费用收缴率', max: maxNum },
              { text: '租决完成率', max: maxNum },
              { text: '客流贡献度', max: maxNum },
              { text: '销售贡献度', max: maxNum }
            ],
            // 设置雷达图中间射线的颜色
            axisLine: {
              lineStyle: {
                color: '#808191'
              }
            },
            center: ['50%', '60%'],
            radius: 80,
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: 'rgba(131,141,158,.3)' // 设置网格的颜色
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: 'rgba(255,0,0,0)' // 图表背景的颜色
              }
            }
          }
        ],
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item'
            },
            areaStyle: {},
            data: [
              {
                value: this.radarData,
                name: this.storeGxdBaseInfoVo.storeName,
                // 设置区域边框和区域的颜色
                itemStyle: {
                  normal: {
                    color: 'rgba(62, 140, 255, 0.5)',
                    lineStyle: {
                      color: 'rgba(62, 140, 255, 1)'
                    }
                  }
                },
                emphasis: {
                  label: {
                    show: false,
                    formatter: '{c}%'
                  }
                }
              }
            ]
          }
        ]
      }
      myChart.setOption(option)
      this.loading = false
    },
    rentCharts (data) {
      const meanVal = []
      const colorList = []
      const barName = data.map((item) => {
        meanVal.push(this.storeLcytGxdAvgVo.avgRentEffectGxdRatio)
        if (item.isCurrentStore) {
          colorList.push('#FFCE73')
        } else {
          colorList.push('#3E8CFF')
        }
        item = item.storeName
        return item
      })
      const barNumber = data.map((item) => {
        item = item.storeGxdRatio
        return item
      })
      this.length1 = colorList.length
      const max = Math.max(...barNumber)
      const maxNum = (max > 100 ? max : 100)
      const myChart = echarts.init(document.getElementById('rent'))
      const option = {
        color: ['#3E8CFF'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          // formatter: '{b}:\n{c}% 均值分数: ' + this.storeLcytGxdAvgVo.avgRentEffectGxdRatio
          formatter: '<span style="color: #808191;">{b}:</span>\n{c}% '
        },
        grid: {
          top: '5%',
          right: '10%',
          left: '6.2%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName,
          axisLabel: {
            margin: 10,
            interval: 0,
            rotate: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          splitArea: {
            show: false,
            interval: 0,
            areaStyle: {
              color: ['red', '#000', 'gray', 'green'] // 这里重点，用于设置颜色的数组，
              // （有了这个属性，那我们完全可以自己在根据某些条件来生成这样一个数组）
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: maxNum,
          axisLabel: {
            formatter: '{value}%',
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber,
          barWidth: '13%',
          itemStyle: {
            normal: {
              color: (params) => {
                return colorList[params.dataIndex]
              }
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: false,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: meanVal,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#A0D7E7',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 30,
            type: 'dotted'
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    costCharts (data) {
      const meanVal = []
      const colorList = []
      const barName = data.map((item) => {
        meanVal.push(this.storeLcytGxdAvgVo.avgCaptureRateGxdRatio)
        if (item.isCurrentStore) {
          colorList.push('#FFCE73')
        } else {
          colorList.push('#7FBA7A')
        }
        item = item.storeName
        return item
      })
      const barNumber = data.map((item) => {
        item = item.storeGxdRatio
        return item
      })
      this.length2 = colorList.length
      // const max = Math.max(...this.barNumber)
      const max = Math.max(...barNumber)
      const maxNum = (max > 100 ? max : 100)
      const myChart = echarts.init(document.getElementById('cost'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '<span style="color: #808191;">{b}:</span>\n{c}% '
          // formatter: '{b}:\n{c} 均值分数: ' + this.storeLcytGxdAvgVo.avgCaptureRateGxdRatio
        },
        grid: {
          top: '5%',
          right: '10%',
          left: '6.2%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName,
          axisLabel: {
            margin: 10,
            interval: 0,
            rotate: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: maxNum,
          axisLabel: {
            formatter: '{value}%',
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber,
          barWidth: '13%',
          itemStyle: {
            normal: {
              color: (params) => {
                return colorList[params.dataIndex]
              }
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: false,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: meanVal,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#7FBA7A',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 30,
            type: 'dotted'
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    leaseCharts (data) {
      const colorList = []
      const meanVal = []
      for (let i = 0; i < data.chartItem.labels.length; i++) {
        meanVal.push(data.avgValue)
        colorList.push('#A0D7E7')
      }
      colorList[data.current - 1] = '#FFCE73'
      const max = Math.max(...data.chartItem.values)
      this.length3 = colorList.length
      const maxNum = (max > 100 ? max : 100)
      const myChart = echarts.init(document.getElementById('lease'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '<span style="color: #808191;">{b}:</span>\n{c}% '
          // formatter: '{b}:\n{c} 均值分数: ' + data.avgValue
        },
        grid: {
          top: '5%',
          right: '10%',
          left: '6.2%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: data.chartItem.labels,
          axisLabel: {
            margin: 10,
            interval: 0,
            rotate: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: maxNum,
          axisLabel: {
            formatter: '{value}%',
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: data.chartItem.values,
          barWidth: '13%',
          itemStyle: {
            normal: {
              color: (params) => {
                return colorList[params.dataIndex]
              }
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: false,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: meanVal,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#FFA2C0',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 30,
            type: 'dotted'
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    flowCharts (data) {
      const meanVal = []
      const colorList = []
      const barName = data.map((item) => {
        meanVal.push(this.storeLcytGxdAvgVo.avgPassengerFlowGxdRatio)
        if (item.isCurrentStore) {
          colorList.push('#FFCE73')
        } else {
          colorList.push('#FFA2C0')
        }
        item = item.storeName
        return item
      })
      const barNumber = data.map((item) => {
        item = item.storeGxdRatio
        return item
      })
      this.length4 = colorList.length
      const max = Math.max(...barNumber)
      const maxNum = (max > 100 ? max : 100)
      const myChart = echarts.init(document.getElementById('flow'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '<span style="color: #808191;">{b}:</span>\n{c}% '
          // formatter: '{b}:\n{c} 均值分数: ' + this.storeLcytGxdAvgVo.avgPassengerFlowGxdRatio
        },
        grid: {
          top: '5%',
          right: '10%',
          left: '6.2%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName,
          axisLabel: {
            margin: 10,
            rotate: 0,
            interval: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: maxNum,
          axisLabel: {
            formatter: '{value}%',
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber,
          barWidth: '13%',
          itemStyle: {
            normal: {
              color: (params) => {
                return colorList[params.dataIndex]
              }
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: false,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: meanVal,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#A0D7E7',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 30,
            type: 'dotted'
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    },
    saleCharts (data) {
      const meanVal = []
      const colorList = []
      const barName = data.map((item) => {
        meanVal.push(this.storeLcytGxdAvgVo.avgSalesEffectGxdRatio)
        if (item.isCurrentStore) {
          colorList.push('#FFCE73')
        } else {
          colorList.push('#8270FF')
        }
        item = item.storeName
        return item
      })
      const barNumber = data.map((item) => {
        item = item.storeGxdRatio
        return item
      })
      // const max = Math.max(...this.barNumber)
      this.length5 = colorList.length
      const max = Math.max(...barNumber)
      const maxNum = (max > 100 ? max : 100)
      const myChart = echarts.init(document.getElementById('sale'))
      const option = {
        color: ['#32E687'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'
            // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: '<span style="color: #808191;">{b}:</span>\n{c}% '
          // formatter: '{b}:\n{c} 均值分数: ' + this.storeLcytGxdAvgVo.avgSalesEffectGxdRatio
        },
        grid: {
          top: '5%',
          right: '10%',
          left: '6.2%',
          bottom: '12%'
        },
        xAxis: [{
          type: 'category',
          color: '#59588D',
          data: barName,
          axisLabel: {
            margin: 10,
            interval: 0,
            rotate: 0,
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          }
        }],
        yAxis: [{
          min: 0,
          max: maxNum,
          axisLabel: {
            formatter: '{value}%',
            color: '#808191',
            textStyle: {
              fontSize: 12
            }
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(107,107,107,0.37)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(226,226,226,0.1)',
              type: 'solid'
            }
          }
        }],
        series: [{
          type: 'bar',
          data: barNumber,
          barWidth: '13%',
          itemStyle: {
            normal: {
              color: (params) => {
                return colorList[params.dataIndex]
              }
            }
          },
          label: {
            normal: {
              formatter: function (params) {
                if (params.value === 0) {
                  return ''
                } else {
                  return params.value
                }
              },
              show: false,
              fontSize: 14,
              fontWeight: 'bold',
              color: '#fff',
              position: 'top'
            }
          }
        }, {
          data: meanVal,
          type: 'line',
          smooth: true,
          name: '折线图',
          symbol: 'none',
          lineStyle: {
            color: '#A0D7E7',
            width: 2,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
            // 设置折线阴影
            shadowBlur: 30,
            shadowOffsetY: 30,
            type: 'dotted'
          }
        }]
      }
      myChart.setOption(option)
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-title-gradients
  width 300px
  span
    font-size 12px
    color #FFCE73
    margin-left 10px
.select-extra>>>
  background #373A43
  .el-input__inner
    box-sizing border-box
    width 110px
    height 34px
    border 1px solid #373A43
    color #808191
  .el-input.is-focus .el-input__inner
    border 1px solid #373A43 !important
  .el-input__inner:hover
    border 1px solid #373A43 !important
.evaluation-store
  margin-right 23px
.store-analysis
  background-color #272930
  padding-bottom 10px
  .title
    padding-left 24px
    padding-top 32px
    color #fff
    font-size 16px
    font-weight bold
  .analysis-info
    margin-top 32px
    display flex
    .info-left
      padding-left 60px
      .text
        background-color #373A43
        // max-width 430px
        height 50px
        color #808191
        font-size 14px
        font-weight 400
        text-align center
        line-height 50px
        padding 0 12px
        span
          font-size 26px
          color #fff
          font-weight 800
          margin-right 10px
        i
          color #B2B3BD
      .charts
        margin-top 40px
        #radar
          width 400px
          height 255px
    .info-right
      margin-left 50px
      .item-content
        display flex
        .item
          flex 1
          border-right 1px solid #373A43
          padding-right 30px
          margin-right 30px
          &:last-child
            margin-right 0
            border none
          .name
            font-size 14px
            color #fff
            font-weight 400
          .percentage
            font-weight 600
            color #fff
            font-size 20px
            margin-top 4px
            margin-bottom 3px
          .text
            color #808191
            font-size 14px
            font-weight 400
            margin-top 3px
            span
              color #B2B3BD
              font-size 14px
              font-weight 400
      .tips-content
        padding-right 90px
        margin-top 60px
        .tips-item
          display flex
          margin 6px 0
          span
            display inline-block
            font-size 14px
            color #fff
            font-weight 400
            white-space nowrap
          p
            font-size 14px
            color #808191
            font-weight 400
            line-height 20px
.charts-wrap
  margin-top 14px
  background-color #272930
  padding-bottom 24px
  .charts-content
    margin-top 34px
    display flex
    .content-left
      width 60%
      .title
        margin-left 24px
        font-size 16px
        color #fff
        font-weight bold
      .charts-info
        margin-top 20px
        #rent, #cost, #lease, #flow, #sale
          width 100%
          height 600px
        .info
          font-size 12px
          color #808191
          float right
          margin-right 10%
          .span1
            display inline-block
            width 8px
            height 8px
            background-color #3E8CFF
            border-radius 50%
          .span2
            display inline-block
            width 8px
            height 8px
            background-color #A0D7E7
            border-radius 50%
            margin-left 30px
    .content-right
      width 40%
      margin-right 24px
      position relative
      .title
        display flex
        .title-left
          .project-name
            font-size 16px
            font-weight bold
            color #fff
        .title-right
          margin-left auto
      .table
        margin-top 20px
        table
          width 100%
          border 1px solid rgba(255, 255, 255, 0.05)
          thead
            tr
              height 57px
              line-height 57px
              border-bottom 1px solid rgba(255, 255, 255, 0.05)
              td
                padding-left 20px
                font-size 14px
                color #B2B3BD
                span
                  font-size 12px
          tbody
            tr
              height 49px
              line-height 49px
              border-bottom 1px solid rgba(255, 255, 255, 0.05)
              cursor pointer
              &.active
                background-color rgba(192, 223, 254, 0.16)
                td
                  color #FFCE73 !important
                  font-weight bold
                  // color #4D5863 !important
              td
                padding-left 20px
                font-size 14px
                color #808191
                &:nth-child(4)
                  color #fff
                &:nth-child(5)
                  color #fff
.no-data
  margin-right 23px
  background-color #272930
.project-text
  color #fff
  margin auto
  font-weight 400
  font-size 14px
  // margin-top 30px
  text-align center
  height 32px
  line-height 32px
  width 490px
  background linear-gradient(to right, rgba(62, 140, 255, 0.01) 25%, rgba(62, 140, 255, 0.18) 50%, rgba(62, 140, 255, 0.01) 75%)
.color2
  background linear-gradient(to right, rgba(127, 186, 122, 0.01) 25%, rgba(127, 186, 122, 0.18) 50%, rgba(127, 186, 122, 0.01) 75%)
.color3
  background linear-gradient(to right, rgba(160, 215, 231, 0.01) 25%, rgba(160, 215, 231, 0.18) 50%, rgba(160, 215, 231, 0.01) 75%)
.color4
  background linear-gradient(to right, rgba(255, 162, 192, 0.01) 25%, rgba(255, 162, 192, 0.18) 50%, rgba(255, 162, 192, 0.01) 75%)
.color5
  background linear-gradient(to right, rgba(130, 112, 255, 0.01) 25%, rgba(130, 112, 255, 0.18) 50%, rgba(130, 112, 255, 0.01) 75%)
.content-right .project-text
  position absolute
  bottom 0
  left 50%
  width 490px
  margin-left -245px
</style>
