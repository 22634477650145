<template>
  <div class="evaluation-wrap">
    <div
      class="evaluation-comprehensive"
      v-loading.fullscreen.lock="loading"
      element-loading-text="加载中..."
      v-if="haveData"
    >
      <div class="brand-vs-wrap">
        <div class="brand-item">
          <div
            class="brand-info"
            v-if="!loading"
          >
            <div class="img-box">
              <img
                v-if="leftStoreDetail.brandLogoUrl "
                :src="leftStoreDetail.brandLogoUrl"
                alt=""
              >
              <img
                v-else
                src="https://jscss.winshangdata.com/3.0/list/images/no-logo.png"
                alt=""
              >
              <span v-if="leftStoreDetail.mainStoreType === '1'">{{leftStoreDetail.mainStoreType === '1' ? '主' : '普'}}</span>
            </div>

            <div class="info">
              <div class="name">{{leftStoreDetail.storeName}} </div>
              <div class="floor">{{leftStoreDetail.floorName}} {{leftStoreDetail.unitNumber}}</div>
              <div class="time">{{leftStoreDetail.openStoreDays}}</div>
              <div class="label">
                <span>{{leftStoreDetail.formatsName}}</span>
              </div>
            </div>
          </div>
          <div class="radar-charts">
            <div id="radar1"></div>
          </div>
        </div>
        <div class="brand-item">
          <div class="item">
            <div class="item-info">
              <img
                v-if="leftStoreDetail.evaluationScore > rightStoreDetail.evaluationScore && leftStoreDetail.evaluationScore !== rightStoreDetail.evaluationScore"
                src="@/assets/images/no1.png"
                alt=""
              >
              <div class="number">{{leftStoreDetail.evaluationScore}}</div>
              <div class="text">综合指数</div>
            </div>
            <div class="item-vs">
              <span>VS</span>
            </div>
            <div class="item-info">
              <img
                v-if="rightStoreDetail.evaluationScore > leftStoreDetail.evaluationScore && leftStoreDetail.evaluationScore !== rightStoreDetail.evaluationScore"
                src="@/assets/images/no1.png"
                alt=""
              >
              <div class="number">{{rightStoreDetail.evaluationScore ? rightStoreDetail.evaluationScore : '0'}}</div>
              <div class="text">综合指数</div>
            </div>
          </div>
        </div>
        <div class="brand-item flex-end">
          <div
            class="brand-info"
            v-if="!loading"
          >
            <img
              :src="rightStoreDetail.brandLogoUrl ? rightStoreDetail.brandLogoUrl : 'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
              alt=""
            >
            <div class="info">
              <div
                class="w-input-item w-input-sty"
                style="width: 166px;"
              >
                <i class="el-icon-search"></i>
                <el-autocomplete
                  v-model="storeName"
                  reserve-keyword
                  hide-loading
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入店铺名"
                  @select="handleSelect"
                ></el-autocomplete>
              </div>
              <div class="floor">{{rightStoreDetail.floorName}} {{rightStoreDetail.unitNumber}}</div>
              <div class="time">{{rightStoreDetail.openStoreDays}}</div>
              <div
                class="label"
                v-if="rightStoreDetail.formatsName"
              >
                <span class="ellipsis">{{rightStoreDetail.formatsName}}</span>
              </div>
            </div>
          </div>
          <div class="radar-charts">
            <div id="radar2"></div>
          </div>
        </div>
      </div>
      <div class="management-wrap">
        <h3 class="w-title-gradients">近一年经营数据对比</h3>
        <div class="table-wrap">
          <div class="flex-start">
            <table>
              <thead>
                <tr>
                  <td colspan="2">{{leftStoreDetail.storeName}}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>月均租金坪效</td>
                  <td>{{leftTableData.avgRentEffect ? leftTableData.avgRentEffect + '元/月/㎡' : '-'}}</td>
                </tr>
                <tr>
                  <td>同楼层同业态月均租金坪效</td>
                  <td>{{leftTableData.lcytAvgRentEffect ? leftTableData.lcytAvgRentEffect + '元/月/㎡' : '-'}}</td>
                </tr>
                <tr>
                  <td>月均销售坪效</td>
                  <td>{{leftTableData.avgSalesEffect ? leftTableData.avgSalesEffect + '元/月/㎡' : '-'}}</td>
                </tr>
                <tr>
                  <td>同楼层同业态月均销售坪效</td>
                  <td>{{leftTableData.lcytAvgSalesEffect ? leftTableData.lcytAvgSalesEffect + '元/月/㎡' : '-'}}</td>
                </tr>
                <tr>
                  <td>月均客流量</td>
                  <td>{{leftTableData.avgPassengerFlowNum ? leftTableData.avgPassengerFlowNum + '千人次' : '-'}}</td>
                </tr>
                <tr>
                  <td>同楼层同业态月均客流量</td>
                  <td>{{leftTableData.lcytAvgPassengerFlowNum ? leftTableData.lcytAvgPassengerFlowNum + '千人次' : '-'}}</td>
                </tr>
                <tr>
                  <td>月均保底租金</td>
                  <td>{{leftTableData.avgBottomRentMoney ? leftTableData.avgBottomRentMoney + '元' : '-'}}</td>
                </tr>
                <tr>
                  <td>月均租金金额</td>
                  <td>{{leftTableData.avgRentMoney ? leftTableData.avgRentMoney + '元' : '-'}}</td>
                </tr>
                <tr>
                  <td>应缴费用总额</td>
                  <td>{{leftTableData.totalBillReceiveMoney ? leftTableData.totalBillReceiveMoney + '元' : '-'}}</td>
                </tr>
                <tr>
                  <td>实缴费用总额</td>
                  <td>{{leftTableData.totalBillPayMoney ? leftTableData.totalBillPayMoney + '元' : '-'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex-end">
            <table>
              <thead>
                <tr>
                  <td colspan="2">{{rightStoreDetail.storeName ? rightStoreDetail.storeName : '-'}}</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>月均租金坪效</td>
                  <td>{{rightTableData.avgRentEffect ? rightTableData.avgRentEffect + '元/月/㎡' : '-'}}</td>
                </tr>
                <tr>
                  <td>同楼层同业态月均租金坪效</td>
                  <td>{{rightTableData.lcytAvgRentEffect ?rightTableData.lcytAvgRentEffect + '元/月/㎡' : '-'}}</td>
                </tr>
                <tr>
                  <td>月均销售坪效</td>
                  <td>{{rightTableData.avgSalesEffect?rightTableData.avgSalesEffect + '元/月/㎡': '-'}}</td>
                </tr>
                <tr>
                  <td>同楼层同业态月均销售坪效</td>
                  <td>{{rightTableData.lcytAvgSalesEffect?rightTableData.lcytAvgSalesEffect + '元/月/㎡':'-'}}</td>
                </tr>
                <tr>
                  <td>月均客流量</td>
                  <td>{{rightTableData.avgPassengerFlowNum?rightTableData.avgPassengerFlowNum + '千人次':'-'}}</td>
                </tr>
                <tr>
                  <td>同楼层同业态月均客流量</td>
                  <td>{{rightTableData.lcytAvgPassengerFlowNum?rightTableData.lcytAvgPassengerFlowNum + '千人次':'-'}}</td>
                </tr>
                <tr>
                  <td>月均保底租金</td>
                  <td>{{rightTableData.avgBottomRentMoney?rightTableData.avgBottomRentMoney + '元':'-'}}</td>
                </tr>
                <tr>
                  <td>月均租金金额</td>
                  <td>{{rightTableData.avgRentMoney?rightTableData.avgRentMoney + '元':'-'}}</td>
                </tr>
                <tr>
                  <td>应缴费用总额</td>
                  <td>{{rightTableData.totalBillReceiveMoney?rightTableData.totalBillReceiveMoney + '元':'-'}}</td>
                </tr>
                <tr>
                  <td>实缴费用总额</td>
                  <td>{{rightTableData.totalBillPayMoney?rightTableData.totalBillPayMoney + '元':'-'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="no-data"
      v-if="hasData"
    >
      <img
        src="@/assets/images/public/not-plandata.png"
        alt=""
      >
      <p>抱歉，暂无数据</p>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
export default {
  data () {
    return {
      loading: true,
      leftStoreDetail: {},
      leftTableData: {},
      rightStoreDetail: {},
      rightTableData: {},
      leftRadarData: [],
      rightRadarData: [],
      storeName: '',
      radarText: ['租金贡献度', '费用收缴率', '租决完成率', '客流贡献度', '销售贡献度'],
      hasData: false,
      haveData: true
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  created () {
    this.init()
    this.getLeftStoreDetail()
  },
  methods: {
    init () {
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.get(api.getDefaultCompareStore, { params: params }).then(res => {
        // console.log(res.data)
        if (res.data.data.storeCode) {
          this.getStoreDetail(res.data.data.storeCode)
        } else {
          this.rightRadarData.push(0)
          this.rightRadarData.push(0)
          this.rightRadarData.push(0)
          this.rightRadarData.push(0)
          this.rightRadarData.push(0)
          this.radarCharts2()
        }
      })
    },
    getLeftStoreDetail () {
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore
      }
      this.axios.get(api.getEvaluationCompareByStoreCode, { params: params }).then(res => {
        this.loading = false
        if (res.data.data.storeTestBaseInfoVo) {
          this.haveData = true
          this.hasData = false
          this.leftStoreDetail = res.data.data.storeTestBaseInfoVo
          if (res.data.data.storeTestOtherInfoVo) {
            this.leftTableData = res.data.data.storeTestOtherInfoVo
          }
          this.leftRadarData.push(res.data.data.storeTestBaseInfoVo.yearRentEffectRatio)
          this.leftRadarData.push(res.data.data.storeTestBaseInfoVo.yearCaptureRate)
          this.leftRadarData.push(res.data.data.storeTestBaseInfoVo.yearTargetRentCompleteRatio)
          this.leftRadarData.push(res.data.data.storeTestBaseInfoVo.yearPassengerFlowRatio)
          this.leftRadarData.push(res.data.data.storeTestBaseInfoVo.yearSalesEffectRatio)
          this.radarCharts1()
        } else {
          this.haveData = false
          this.hasData = true
        }
      })
    },
    getStoreDetail (storeCode) {
      const params = {
        projectId: this.filtroProject,
        storeCode: storeCode,
        toCompareStoreCode: this.filtroStore
      }
      this.axios.get(api.getEvaluationCompareByStoreCode, { params: params }).then(res => {
        // console.log(res)
        this.rightStoreDetail = res.data.data.storeTestBaseInfoVo
        this.rightTableData = res.data.data.storeTestOtherInfoVo
        this.rightRadarData.push(res.data.data.storeTestBaseInfoVo.yearRentEffectRatio)
        this.rightRadarData.push(res.data.data.storeTestBaseInfoVo.yearCaptureRate)
        this.rightRadarData.push(res.data.data.storeTestBaseInfoVo.yearTargetRentCompleteRatio)
        this.rightRadarData.push(res.data.data.storeTestBaseInfoVo.yearPassengerFlowRatio)
        this.rightRadarData.push(res.data.data.storeTestBaseInfoVo.yearSalesEffectRatio)
        this.storeName = res.data.data.storeTestBaseInfoVo.storeName
        this.radarCharts2()
      })
    },
    querySearchAsync (queryString, cb) {
      if (queryString) {
        const params = {
          storeName: queryString,
          projectId: this.filtroProject,
          exceptStore: this.filtroStore
        }
        this.axios.get(api.searchStore, { params: params })
          .then((res) => {
            res.data.data = res.data.data.map((item) => {
              item.value = item.storeName
              item.id = item.storeCode
              return item
            })
            cb(res.data.data)
          })
      } else {
        const array = []
        cb(array)
      }
    },
    handleSelect (item) {
      // console.log(item)
      this.rightRadarData = []
      this.getStoreDetail(item.storeCode)
    },
    radarCharts1 () {
      const max = Math.max(...this.leftRadarData)
      const maxNum = (max > 100 ? max : 100)
      const myChart = echarts.init(document.getElementById('radar1'))
      const option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        legend: {
          left: 'center',
          data: [],
          show: false
        },
        formatter: (params) => {
          let relVal = '<span style="color: #B2B3BD;">' + params.name + '</span>'
          for (let i = 0; i < params.data.value.length; i++) {
            relVal += '<br/><span style="color: #808191;">' + this.radarText[i] + ' : </span>' + params.data.value[i] + ' ' + '%'
            // relVal += '<br/>' + this.radarText[i] + ' : ' + params.data.value[i] + ' ' + '%'
          }
          return relVal
        },
        radar: [
          {
            indicator: [
              {
                text: '租金贡献度',
                max: maxNum,
                // 若将此属性放在radar下，则每条indicator都会显示圈上的数值，放在这儿，只在通信这条indicator上显示
                axisTick: {
                  show: true,
                  fontSize: 12,
                  color: '#838D9E',
                  showMaxLabel: false, // 不显示最大值，即外圈不显示数字30
                  showMinLabel: true // 显示最小数字，即中心点显示0
                }
              },
              { text: '费用收缴率', max: maxNum },
              { text: '租决完成率', max: maxNum },
              { text: '客流贡献度', max: maxNum },
              { text: '销售贡献度', max: maxNum }
            ],
            // 设置雷达图中间射线的颜色
            axisLine: {
              lineStyle: {
                color: '#808191'
              }
            },
            center: ['40%', '60%'],
            radius: 80,
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: 'rgba(131,141,158,.3)' // 设置网格的颜色
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: 'rgba(255,0,0,0)' // 图表背景的颜色
              }
            }
          }
        ],
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item'
            },
            areaStyle: {},
            data: [
              {
                value: this.leftRadarData,
                name: this.leftStoreDetail.storeName,
                // 设置区域边框和区域的颜色
                itemStyle: {
                  normal: {
                    color: 'rgba(62, 140, 255, 0.5)',
                    lineStyle: {
                      color: 'rgba(62, 140, 255, 1)'
                    }
                  }
                }
              }
            ]
          }
        ]
      }
      myChart.setOption(option)
      this.loading = false
    },
    radarCharts2 () {
      const max = Math.max(...this.rightRadarData)
      const maxNum = (max > 100 ? max : 100)
      const myChart = echarts.init(document.getElementById('radar2'))
      const option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(0,0,0,0.85)',
          padding: 10
        },
        legend: {
          left: 'center',
          data: [],
          show: false
        },
        formatter: (params) => {
          let relVal = '<span style="color: #B2B3BD;">' + params.name + '</span>'
          for (let i = 0; i < params.data.value.length; i++) {
            relVal += '<br/><span style="color: #808191;">' + this.radarText[i] + ' : </span>' + params.data.value[i] + ' ' + '%'
            // relVal += '<br/>' + this.radarText[i] + ' : ' + params.data.value[i] + ' ' + '%'
          }
          return relVal
        },
        radar: [
          {
            indicator: [
              {
                text: '租金贡献度',
                max: maxNum,
                // 若将此属性放在radar下，则每条indicator都会显示圈上的数值，放在这儿，只在通信这条indicator上显示
                axisTick: {
                  show: true,
                  fontSize: 12,
                  color: '#838D9E',
                  showMaxLabel: false, // 不显示最大值，即外圈不显示数字30
                  showMinLabel: true // 显示最小数字，即中心点显示0
                }
              },
              { text: '费用收缴率', max: maxNum },
              { text: '租决完成率', max: maxNum },
              { text: '客流贡献度', max: maxNum },
              { text: '销售贡献度', max: maxNum }
            ],
            // 设置雷达图中间射线的颜色
            axisLine: {
              lineStyle: {
                color: '#808191'
              }
            },
            center: ['40%', '60%'],
            radius: 80,
            splitLine: {
              show: true,
              lineStyle: {
                width: 1,
                color: 'rgba(131,141,158,.3)' // 设置网格的颜色
              }
            },
            splitArea: {
              show: true,
              areaStyle: {
                color: 'rgba(255,0,0,0)' // 图表背景的颜色
              }
            }
          }
        ],
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item'
            },
            areaStyle: {},
            data: [
              {
                value: this.rightRadarData,
                name: this.rightStoreDetail.storeName,
                // 设置区域边框和区域的颜色
                itemStyle: {
                  normal: {
                    color: 'rgba(62, 140, 255, 0.5)',
                    lineStyle: {
                      color: 'rgba(62, 140, 255, 1)'
                    }
                  }
                }
              }
            ]
          }
        ]
      }
      myChart.setOption(option)
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.evaluation-comprehensive
  margin-right 23px
  .brand-vs-wrap
    background-color #272930
    padding-top 70px
    padding-left 170px
    padding-right 170px
    padding-bottom 24px
    display flex
    justify-content center
    .brand-item
      flex 1
      .brand-info
        display flex
        // align-items center
        .img-box
          position relative
          border-radius 2px
          span
            position absolute
            right 16px
            width 28px
            height 28px
            color #fff
            text-align center
            line-height 28px
            background-color #FF7134
            font-size 17px
            font-weight 400
            vertical-align middle
            border-radius 0px 2px 0px 2px
        img
          width 180px
          height 150px
          border 1px solid #424752
          object-fit cover
          margin-right 16px
          border-radius 2px
        .info
          .name
            font-size 18px
            font-weight bold
            color #fff
            margin-bottom 24px
            margin-top 5px
          .floor
            font-size 14px
            font-weight 400
            color #808191
          .time
            font-size 14px
            font-weight 400
            color #808191
          .label
            margin-top 24px
            span
              display inline-block
              max-width 190px
              padding 3px 8px
              background-color #3D3F45
              color #B2B3BD
              font-size 12px
              border-radius 10px
          .w-input-item
            background-color #373A43
            border-radius 2px
            height 33px
            line-height 32px
            margin-bottom 20px
            padding-left 10px
            margin-top 5px
            input
              background none
              border none !important
              height 33px
              padding-left 10px
              color #fff
      .item
        width 380px
        display flex
        background-color #2C2E38
        height 130px
        align-items center
        justify-content center
        .item-info
          flex 2
          text-align center
          position relative
          img
            width 30%
            position absolute
            top -40px
            left 50%
            margin-left -20px
          .number
            font-size 60px
            font-weight 600
            color #fff
          .text
            font-size 16px
            font-weight 400
            color #fff
        .item-vs
          margin 0 24px
          span
            display inline-block
            width 40px
            height 40px
            border-radius 50%
            background rgba(192, 223, 254, 0.2)
            text-align center
            line-height 40px
            color #fff
      .radar-charts
        #radar1, #radar2
          width 400px
          height 250px
    .flex-end
      margin-left auto
.management-wrap
  margin-top 14px
  background-color #272930
  padding-bottom 24px
  .table-wrap
    display flex
    margin-top 30px
    .flex-start, .flex-end
      // max-width 670px
      // min-width 410px
      flex 3
      padding 0 80px
      table
        width 100%
        border 1px solid rgba(255, 255, 255, 0.1)
        thead
          tr
            height 48px
            border-bottom 1px solid rgba(255, 255, 255, 0.1)
            td
              text-align center
              font-size 18px
              font-weight bold
              color #fff
        tbody
          tr
            height 48px
            border-bottom 1px solid rgba(255, 255, 255, 0.1)
            td
              font-size 14px
              padding-left 40px
              &:first-child
                color #808191
                border-right 1px solid rgba(255, 255, 255, 0.1)
                width 50%
              &:last-child
                color #fff
                width 50%
.no-data
  margin-right 23px
  background-color #272930
</style>
